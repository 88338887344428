<template>
    <div class="invitaio">
        <el-steps :active="active" finish-status="success" align-center>
            <el-step title="填写商品基础信息"></el-step>
            <!-- <el-step title="上传合同信息"></el-step> -->
            <el-step title="选择用户发起"></el-step>
        </el-steps>
        <div class="conetent">
            <foundation @change="foundationChange" v-if="active==0"></foundation>
            <!-- <agreement @change="agreementChange" @PreviousStep="PreviousStep" v-if="active==1"></agreement> -->
            <selects  @change="selectsChange"  @PreviousStep="PreviousStep" v-if="active==1"></selects>
        </div>
    </div>
</template>

<script>
    import foundation from './components/foundation.vue'
    import agreement from './components/agreement.vue'
    import selects from './components/select.vue'
    import { getInviteProgress } from "@/api/foundation";
    export default {
        data() {
            return {
                active: 0,
            };
        },
        components: {
            foundation,
            agreement,
            selects
        },
        mounted() {
            this.getInviteProgress()
        },
        methods: {
            PreviousStep(e){
                this.active=e
            },
            getInviteProgress() {
                getInviteProgress().then(res => {
                    console.log(res)
                    if (res.data?.goodsInfo) {
                        this.active = 1
                    }
                    if (res.data?.contractInfoList) {
                        this.active = 2
                    } 
                })
            },
            foundationChange(e) {
                if (e.code == 0) {
                    this.active = 1
                }
            },
            agreementChange(e){
                if(e.code==0){
                    this.active = 2
                }
            },
            selectsChange(e){
                if(e.code==0){
                    this.$router.push({
                        path:'/Invitation'
                    })
                }
            }
        },
    };
</script>

<style lang="scss" scoped>
    .invitaio {
        width: 80%;
        margin: 20px auto;
    }
    ::v-deep .el-steps{
        min-width: 1200px;
    }
    .conetent {
        background-color: #fff;
        width: 1200px;
        margin: 20px auto;
        padding: 20px;
    }
</style>